@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family:
      'Noto Sans',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

body {
  margin: 0;
}

body.modal-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-handle-last-row::after {
  content: '';
  flex: auto;
}

.layout {
  height: calc(var(--vh, 1vh) * 100);
}

h1 {
  @apply text-md sm:text-md md:text-lg;
}

h2 {
  @apply text-base sm:text-md md:text-md;
}

h3 {
  @apply text-sm sm:text-md md:text-sm;
}

h4 {
  @apply text-md sm:text-md md:text-md;
}

h5 {
  @apply text-xs sm:text-xs md:text-xs;
}

h6 {
  @apply text-xs sm:text-xs md:text-xs;
}
